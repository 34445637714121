// SOL MENU

import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import DashboardIcon from '@mui/icons-material/Dashboard';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PostAddIcon from '@mui/icons-material/PostAdd';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CategoryIcon from '@mui/icons-material/Category';
import ViewListIcon from '@mui/icons-material/ViewList';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import GroupIcon from '@mui/icons-material/Group';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

// import Iconify from 'src/components/iconify';
// import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

// const icon = (name, isIconify) =>
//   !isIconify ? (
//     <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
//   ) : (
//     <Iconify icon={name} />
//   );
// https://icon-sets.iconify.design/solar/
// https://www.streamlinehq.com/icons

const ICONS = {
  // job: icon('ic_job'),
  // blog: icon('ic_blog'),
  // chat: icon('ic_chat'),
  // mail: icon('ic_mail'),
  dashboard: <DashboardIcon />,
  workorder: <AssignmentIcon />,
  catalog: <CategoryIcon />,
  catalogList: <ViewListIcon />,
  catalogNew: <AddBoxIcon />,
  customer: <PeopleIcon />,
  customerList: <PeopleOutlineIcon />,
  customerNew: <PersonAddIcon />,
  project: <WorkOutlineIcon />,
  projectList: <ListAltIcon />,
  projectNew: <PostAddIcon />,
  order: <ShoppingCartIcon />,
  orderList: <FormatListBulletedIcon />,
  orderNew: <AddShoppingCartIcon />,
  user: <SupervisedUserCircleIcon />,
  userList: <GroupIcon />,
  userNew: <PersonAddAlt1Icon />
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // Overview
      {
        subheader: t('overview'),
        items: [
          {
            title: t('app'),
            path: paths.dashboard.root,
            icon: ICONS.dashboard
          }
        ]
      },
      // Production
      {
        subheader: t('production'),
        items: [
          {
            title: t('project-management'),
            path: paths.production.projectManagement.root,
            icon: ICONS.project,
            children: [
              {
                title: t('project-list'),
                path: paths.production.projectManagement.root,
                icon: ICONS.projectList
              },
              {
                title: t('project-new'),
                path: paths.production.projectManagement.create,
                icon: ICONS.projectNew
              }
            ]
          },
          // {
          //   title: 'Üretim İşleri',
          //   path: paths.production.projectManagement.root,
          //   icon: ICONS.booking,
          // },
          {
            title: 'İş Emirleri',
            path: paths.workOrder.root,
            icon: ICONS.workorder
          },
          {
            title: 'İş Emirleri (YENİ)',
            path: paths.taskQueue.root,
            icon: ICONS.workorder
          }
        ]
      },
      // Catalog
      {
        subheader: t('catalog'),
        items: [
          {
            title: t('catalog-management'),
            path: paths.catalog.catalogManagement.root,
            icon: ICONS.catalog,
            children: [
              {
                title: t('catalog-list'),
                path: paths.catalog.catalogManagement.root,
                icon: ICONS.catalogList
              },
              {
                title: t('catalog-new'),
                path: paths.catalog.catalogManagement.create,
                icon: ICONS.catalogNew
              }
            ]
          }
        ]
      },
      // Management
      {
        subheader: 'Satış',
        items: [
          {
            title: t('order-management'),
            path: paths.order.root,
            icon: ICONS.order,
            children: [
              { title: t('order-list'), path: paths.order.root, icon: ICONS.orderList },
              {
                title: t('order-create'),
                path: paths.order.new,
                icon: ICONS.orderNew
              }
            ]
          }
        ]
      },
      {
        subheader: t('management'),
        items: [
          {
            title: t('user-management'),
            path: paths.user.root,
            icon: ICONS.user,
            children: [
              {
                title: t('user-list'),
                path: paths.user.list,
                icon: ICONS.userList
              },
              {
                title: t('user-new'),
                path: paths.user.new,
                icon: ICONS.userNew
              }
            ]
          },
          {
            title: t('customer-management'),
            path: paths.customer.root,
            icon: ICONS.customer,
            children: [
              {
                title: t('customer-list'),
                path: paths.customer.customerManagement.root,
                icon: ICONS.customerList
              },
              {
                title: t('customer-new'),
                path: paths.customer.customerManagement.create,
                icon: ICONS.customerNew
              }
            ]
          }
        ]
      }
    ],
    [t]
  );

  return data;
}
